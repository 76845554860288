/**
 * CONTENT-PLACEHOLDER
 *
 * Component used in loading status to show a preview of what real results/contents will be.
 */
 
/* ------------------------------------ *\
   #CONTENT-PLACEHOLDER
\* ------------------------------------ */
.n7-content-placeholder {
    &__item {
        // background-color: $color-background-light;
        // height: $space*2;
        // width: auto;
        // margin-bottom: $space;
        // border-radius: $border-radius;

        // animation-duration: 1.3s;
        // animation-fill-mode: forwards;
        // animation-iteration-count: infinite;
        // animation-name: contentPlaceholderAnimation;
        // animation-timing-function: linear;
        background: linear-gradient(to right, darken($color-background-medium, 2) 0%, darken($color-background-medium, 5) 30%, darken($color-background-medium, 2) 60%);
        background-size: 500px 104px;
        position: relative;
    }

    // Example application
    .thumb-placeholder {
        // float: left;
        // height: $space*7;
        // width: $space*7;
        // border-radius: 50%;
    }

    .title-placeholder {
        // margin-left: $space*9;
    }

    .text-placeholder {
        // margin-left: $space*9;
        // height: $space*4;
    }

    .text-placeholder + .n7-content-placeholder__item {
        // clear: both;
    }
}

@keyframes contentPlaceholderAnimation{
    0%{
        background-position: 0 0
    }
    100%{
        background-position: 1000px 0
    }
}


/* ------------------------------------ *\
   #MEDIA-QUERIES
\* ------------------------------------ */
@media all and (max-width: $breakpoint-laptop) {
}

@media all and (max-width: $breakpoint-ipad-portrait) {
}

@media all and (max-width: $breakpoint-smartphone-landscape) {
}

/* Retina */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}