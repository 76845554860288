/**
 * DATA-WIDGET
 *
 * Data widget component style
 * 
 */

/* ------------------------------------ *\
    DATA-WIDGET
\* ------------------------------------ */
.n7-data-widget {
    &__main-title {
        font-family: $font-family-main;
        font-size: $font-size-xxxl;
        color: $color-text;
    }

    &__sub-info {
        font-weight: $font-weight-bold;
    }

    &__sub-title {
        color: $color-text-light; // $color-text-lighter;
    }

    &.is-negative {
        .n7-data-widget__sub-info {
            color: $color-error;
        }
    }

    &.is-positive {
        .n7-data-widget__sub-info {
            color: $color-success;
        }
    }
}


/* ------------------------------------ *\
   #MEDIA-QUERIES
\* ------------------------------------ */
@media all and (max-width: $breakpoint-laptop) {
}

@media all and (max-width: $breakpoint-ipad-portrait) {
}

@media all and (max-width: $breakpoint-smartphone-landscape) {
}

/* Retina */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}