/**
 * DATA WIDGET WRAPPER
 *
 * Style for the component that wraps and inner title on top
 * and data-widgets and/or graphs in the bottom section
 */
 
/* ------------------------------------ *\
    DATA-WIDGET-WRAPPER
\* ------------------------------------ */
.dv-data-widget-wrapper {
    // display: flex;
    // flex-direction: column;
}


/* ------------------------------------ *\
    INNER-TITLE
\* ------------------------------------ */
.dv-data-widget-wrapper {
    &__title {
        // height: $data-widget-wrapper-header-height;
        // flex-shrink: 0;
        // flex-grow: 0;
    }

    /* Style for n7-inner-title component */
    .n7-inner-title {
        // padding: 0;
    }

    .n7-inner-title__title {
        // font-size: $font-size-s;
        // color: $color-text;
        // font-weight: $font-weight-bold;
    }

    .n7-inner-title__subtitle {
        // font-size: $font-size-xs;
        // color: $color-text-light;
    }
}


/* ------------------------------------ *\
    CONTENT
\* ------------------------------------ */


/* ------------------------------------ *\
    FIXED-HEIGHT
\* ------------------------------------ */
.dv-data-widget-wrapper-fixed-height {
    // height: $data-widget-wrapper-height;e
    
    .dv-data-widget-wrapper__content {
        // flex-shrink: 1;
        // flex-grow: 1;
        // display: flex;
        // flex-direction: column;
    }

    .dv-data-widget-wrapper__content-row {
        // flex-shrink: 1;
        // flex-grow: 1;
        // flex-basis: 1px;
        // overflow: hidden;
    
        // display: flex;
        // align-items: center;
        > * {
            flex-shrink: 0;
            max-width: 100%;
        }
    }
}


/* ------------------------------------ *\
   #MEDIA-QUERIES
\* ------------------------------------ */
@media all and (max-width: $breakpoint-laptop) {
}

@media all and (max-width: $breakpoint-ipad-portrait) {
}

@media all and (max-width: $breakpoint-smartphone-landscape) {
}

/* Retina */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}


/* ------------------------------------ *\
   #PRINT
\* ------------------------------------ */
@media print {

    .dv-data-widget-wrapper {
        // display: block;
    }

    /* Inner title */
    .dv-data-widget-wrapper {
        &__title {
            // height: auto;
            // margin-bottom: $space*2;
        }
    
        .n7-inner-title__title {
            // font-size: $font-size-m;
        }
    
        .n7-inner-title__subtitle {
            // font-size: $font-size-s;
        }
    }

    /* Row */
    .dv-data-widget-wrapper {
        &__content-row {
            // margin-bottom: $space*2;
        }
    }
    
    /* Fixed height, disabled in print */
    .dv-data-widget-wrapper-fixed-height {
        height: auto;
        
        .dv-data-widget-wrapper__content {
            display: block;
        }
    
        .dv-data-widget-wrapper__content-row {
            display: block;
        }
    }

}