/**
 * HEADER
 *
 * Style for header, including main navigation
 */

/* ------------------------------------ *\
    HEADER
\* ------------------------------------ */
.n7-header {
    background-color: $header-bg-color;
    box-shadow: 0 1px 15px rgba(1,1,1,.2);
}