//
// VARIABLES
//
// Definition of all variables used by the components library
//

// ------------------------------------ //
// #LAYOUT
// ------------------------------------ //
$page-max-width: 1300px;


// ------------------------------------ //
// #SCHEDA-LAYOUT
// ------------------------------------ //
// $aw-scheda-tree-width: 400px;


// ------------------------------------ //
// #GRIDS
// ------------------------------------ //
$grid-gutter-h: $space*2; // $space*3;
$grid-gutter-v: 0;


// ------------------------------------ //
// #SPACING-UNIT
// ------------------------------------ //
// $space: 8px;


// ------------------------------------ //
// #FONTS
// ------------------------------------ //
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700,700i&display=swap');

@font-face {
    font-family: 'campton';
    src: url('assets/fonts/campton/campton_black-webfont.woff2') format('woff2'),
         url('assets/fonts/campton/campton_black-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'campton';
    src: url('assets/fonts/campton/campton_bold-webfont.woff2') format('woff2'),
         url('assets/fonts/campton/campton_bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'campton';
    src: url('assets/fonts/campton/campton_light-webfont.woff2') format('woff2'),
         url('assets/fonts/campton/campton_light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'campton';
    src: url('assets/fonts/campton/campton_light-webfont.woff2') format('woff2'),
         url('assets/fonts/campton/campton_light-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;

}

@font-face {
    font-family: 'campton';
    src: url('assets/fonts/campton/campton_medium-webfont.woff2') format('woff2'),
         url('assets/fonts/campton/campton_medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'campton';
    src: url('assets/fonts/campton/campton_semibold-webfont.woff2') format('woff2'),
         url('assets/fonts/campton/campton_semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}


// ------------------------------------ //
// TYPOGRAPHY
// ------------------------------------ //
$font-family-main: 'Open Sans', sans-serif;

$font-family-headings: 'campton', sans-serif;

// $font-size-xxxl: 38px;
$font-size-xxl: 26px;
$font-size-xl: 22px;
// $font-size-l: 18px;
$font-size-m: 15px;
$font-size-s: 13px;
// $font-size-xs: 12px;

// Font weight
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-black: 900;

// ------------------------------------ //
// COLORS
// ------------------------------------ //
// Text Colors
// $color-text: $color-gray-06;
// $color-text-inverse: $color-gray-00;
$color-text-light: $color-gray-04; // $color-gray-05;
// $color-text-lighter: $color-gray-04;
// $color-text-link: $color-main;
// $color-text-link-hover: $color-text-hover;
// $color-text-link-visited: $color-text-hover;
$color-text-form-placeholder: $color-gray-04;

// Borders
// $color-border-light: $color-gray-02;
// $color-border-normal: $color-gray-03;

// Backgrounds
$color-background-base: $color-gray-00;
// $color-background-light: $color-gray-01;
// $color-background-light-blue: #F5F7FF;
$color-background-medium: $color-gray-02;
// $color-background-dark: $color-gray-03;


// ------------------------------------ //
// #MEDIA-QUERIES-BREAKPOINT
// ------------------------------------ //
// $breakpoint-desktop-wide: 1180px;
// $breakpoint-laptop: 960px;
// $breakpoint-ipad-portrait: 768px;
// $breakpoint-smartphone-landscape: 480px;
// $breakpoint-smartphone-portrait: 400px;
$breakpoint-layout-change: 1000px;

/* Inner title brealpoints */
$breakpoint-inner-title-medium: 0;
$breakpoint-inner-title-small: 0;


// ------------------------------------ //
// #BORDER-RADIUS
// ------------------------------------ //
$border-radius: 5px;
// $border-radius-hard: 8px;


// ------------------------------------ //
// #BUTTONS
// ------------------------------------ //
$button-border-width: 2px;
// $button-border-radius: $border-radius;
// $button-small-height: 24px;
// $button-normal-height: 32px;
// $button-large-height: 56px;

// Normal button
$color-button-text: $color-second;
$color-button-text-hover: darken($color-second, 15%);
$color-button-text-disabled: rgba($color-second, .4);

$color-button-bg: #ffffff;
// $color-button-bg-hover: #E0E7FF;
// $color-button-bg-disabled: #E0E7FF;

$color-button-border: $color-second;
$color-button-border-hover: darken($color-second, 15%);

// Call to action button
// $color-button-cta-text: $color-text-inverse;
// $color-button-cta-text-hover: $color-text-inverse;
// $color-button-cta-text-disabled: rgba($color-text-inverse, .4);

$color-button-cta-bg: $color-main;
$color-button-cta-border: $color-main;
$color-button-cta-bg-hover: darken($color-button-cta-bg, 5%);
$color-button-cta-border-hover: darken($color-button-cta-bg, 5%);
$color-button-cta-bg-active: #3fe3f1;
$color-button-cta-bg-disabled: rgba($color-button-cta-bg, .4);

// $color-button-cta-border: darken($color-main, 5); // $color-text-hover;

// Light button (no background or border, only text)
$color-button-light-text: $color-text;
// $color-button-light-text-hover: $color-text-link-hover;
// $color-button-light-text-disabled: $color-text-light;
// $color-button-light-text-active: $color-text-link-hover;


// ------------------------------------ //
// #FORMS
// ------------------------------------ //
// $form-input-height: 38px;
// $form-input-small-height: 26px;
// $form-border-radius: $border-radius;
// $form-dropdown-height: 30px;

// ------------------------------------ //
// #TRANSITIONS
// ------------------------------------ //
// $transition-durations: 0.2s;


// ------------------------------------ //
// #HEADER
// ------------------------------------ //
$header-height: 50px;
$header-bg-color: $color-main;
$header-height-mobile: 50px;
// $header-active-item-color: #F7FAFA;
$header-logo-height: $header-height;
$header-logo-height-mobile: $header-height;
// $header-user-thumb-size: 40px;
// $header-user-thumb-size-mobile: 38px;
// $header-search-input-height: 36px;
// $header-search-input-height-mobile: 28px;
$header-breakpoint-mobile: 0;
$header-breakpoint-mobile-small: 0;


// ------------------------------------ //
// #LOADER
// ------------------------------------ //
// $loader-size: 70px;
// $loader-thickness: 5px;
// $loader-color-front: $color-main;
// $loader-color-back: rgba($color-main, .3);


// ------------------------------------ //
// #TABLE
// ------------------------------------ //
$table-border-color: $color-border-normal;
$table-border-size: 1px;
/* Header */
// $table-header-font-size: $font-size-xs;
// $table-header-text-color: $color-text;
// $table-header-bg: $color-background-light-blue;
/* Contents */
// $table-font-size: $font-size-s;
// $table-text-color: $color-text;
$table-bg-odd: $color-background-medium;
$table-bg-even: $color-background-base;
/* Footer */
// $table-footer-font-size: $font-size-s;
// $table-footer-text-color: $color-text-light;
// $table-footer-bg: $color-background-medium;


// ------------------------------------ //
// #FOOTER
// ------------------------------------ //
$footer-image-height: 30px;
$footer-background-color: $color-background-base;
// $footer-breakpoint-desktop: $page-max-width + $grid-gutter-h;
// $footer-breakpoint-mobile: 700px;

// ------------------------------------ //
// #NAV
// ------------------------------------ //
$nav-item-active-shadow: 0 0 10px 0 rgba(0,0,0,0.07);
$nav-item-active-gradient: linear-gradient(270deg, #f0f0f0 2%, #fbfbfb 10%, #ffffff 69%);


// ------------------------------------ //
// #LAYOUT CONTENT
// ------------------------------------ //
$layout-wrapper-box-shadow: 0 5px 40px 0 rgba(0,0,0,0.06);
$layout-inner-title-gradient: linear-gradient(180deg, rgba(0,0,0,0.00) 50%, rgba(0,0,0,0.03) 100%);
$layout-title-image-box-shadow: 0 5px 40px 0 rgba(0,0,0,0.06);

// ------------------------------------ //
// #CARD
// ------------------------------------ //
$card-wrapper-background: $color-gray-02;
$card-wrapper-box-shadow: inset 0 0 5px 0 rgba(0,0,0,0.04);
$card-box-shadow: 0 4px 20px 0 rgba(0,0,0,0.07);
$card-border-radius: 10px;

// ------------------------------------ //
// #ITEM
// ------------------------------------ //
$item-wrapper-background: $color-gray-02;
$item-box-shadow: 0 4px 20px 0 rgba(0,0,0,0.07);
$item-border-radius: 10px;
$item-background-color: #ffffff;
$item-label-color: $color-second;
$item-label-background: transparentize($color-second, 0.9);
$item-label-min-height: 60px;

// ------------------------------------ //
// #CHART-TOOLTIP
// ------------------------------------ //
$chart-tooltip-max-width: 350px;
$chart-tooltip-border-color: #e3e3e3;
$chart-tooltip-box-shadow: 0 2px 4px 0 rgba(0,0,0,0.09);

// ------------------------------------ //
// #DATA-WIDGET-WRAPPER
// ------------------------------------ //
$data-widget-wrapper-height: 265px;
$data-widget-wrapper-header-height: 44px;
// $data-widget-wrapper-width: auto;