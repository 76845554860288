/**
 * LAYOUT
 *
 * Css definining the style for the layout
 */

/* ------------------------------------ *\
    WRAPPER
\* ------------------------------------ */
.dc-layout-wrapper {
}


/* ------------------------------------ *\
    LAYOUT
\* ------------------------------------ */
.dc-layout {
    max-width: $page-max-width;
    min-width: 960px;
    margin: 0 auto;
    
    &__content {
        display: flex;
        margin-bottom: $space*4;
    }

    &__title {
    }

    &__sidebar {
        width: 238px;
        flex-shrink: 0;
        flex-grow: 0;
    }

    &__main {
        flex: 1;
    }

    /* Wraps all the sections inside the page */
    &__section-wrapper {
        padding: $space*2 $space*2 0 $space*2;
    }
}

.dc-layout-clear {
    clear: both;
}

.dc-layout-table {
    width: 100%;
}


/* ------------------------------------ *\
    STICKY FOOTER
\* ------------------------------------ */
/* Implemented with CSS flex */
.n7-main-layout {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.n7-page-content {
    flex: 1 0 auto; /* for sticky footer - prevent element from shrinking */

    + * {
        flex: none; /* for sticky footer - prevent element from shrinking */
    }
}


/* ------------------------------------ *\
    PAGE-TITLE // Company name
\* ------------------------------------ */
.dc-layout__title {
    .n7-inner-title {
        min-width: 960px;
        padding-right: 0;
        padding-top: $space*4;
        padding-bottom: $space*4;
        padding-left: 0;

        &__title {
            margin-bottom: 0;
            font-size: $font-size-xxl;
            line-height: 1.25;
        }

        &__tools {
            flex: 1 0 auto;
            justify-content: flex-end;
            padding-left: $space*2;
        }

        &__tools-label {
            font-size: $font-size-m;
        }

        &__buttons-action {
            box-shadow: 0 3px 15px rgba(0,0,0,0.1);
        }

        &__image-left {
            margin-right: $space*1.5;
        }
    }
}


/* ------------------------------------ *\
    NAV
\* ------------------------------------ */
.dc-layout {
    .n7-nav__list {
        background-color: transparent;
    }

    .n7-nav__link {
        display: block;
        padding: $space*1.5 $space*2;
        border-radius: 3px 0 0 3px;
        transition: all $transition-durations;

        &:hover {
            .n7-nav__label {
                color: $color-text-link;
            }        
        }
    }

    .n7-nav__label {
        color: $color-text-light;
        transition: all $transition-durations;
    }

    /* Active */
    .n7-nav__item.is-active {
        .n7-nav__link {
            background: $nav-item-active-gradient;
            box-shadow: $nav-item-active-shadow;
            font-weight: $font-weight-bold;
        }

        .n7-nav__label {
            color: $color-text;
        } 
    }
}


/* ------------------------------------ *\
    MAIN-CONTENT
\* ------------------------------------ */
.dc-layout {
    &__main-content-wrapper {
        border-radius: $border-radius;
        background-color: $color-background-base;
        box-shadow: $layout-wrapper-box-shadow;
        overflow: hidden;
    }

    /* Title inside the page */
    &__inner-title {
        margin: 0;
        padding: $space*2;
        border-top-right-radius: $border-radius;
        border-top-left-radius: $border-radius;
        font-weight: $font-weight-normal;
        font-size: $font-size-xxl;
        background-image: $layout-inner-title-gradient;

        strong {
            display: inline-block;
            text-transform: lowercase;
            &::first-letter {
                text-transform: uppercase !important;
            }
        }
    }

    /* Title with image background, used in tha last page */
    &__inner-title-image {
        position: relative;
        display: block;
        height: 200px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: left top;
        box-shadow: $layout-title-image-box-shadow;

        &:after {
            position: absolute;
            z-index: 1;
            content: " ";
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: rgba(0,0,0,.3);
        }

        h2 {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            z-index: 20;
            margin-bottom: 0;
            text-align: center;
            color: $color-text-inverse;
            font-size: $font-size-xxxl;
            font-weight: $font-weight-normal;
        }
    }
}


/* ------------------------------------ *\
    GRIDS
\* ------------------------------------ */
@media all and (max-width: $breakpoint-layout-change) {

    /* .dc-layout-row.n7-grid-2 {
        > * {
            width: 100%;
            margin-right: 0;

            &:nth-of-type(1n) {
                margin-right: 0;
            }
        }
    }

    .dc-section-content.n7-grid-3 {
        > * {
            width: 100%;
            margin-right: 0;

            &:nth-of-type(1n) {
                margin-right: 0;
            }
        }
    } */

}


/* ------------------------------------ *\
    LOADING
\* ------------------------------------ */
.dc-layout.dc-layout-loading {
    /* Title */
    .dc-layout__title {
        padding: $space*4 0;
        
        > * {
            width: 100%;
        }

        .n7-content-placeholder {
            width: 100%;
            display: flex;
            align-items: center;
        }
    }

    .dv-title-image-loading {
        height: 60px;
        width: 60px;
        margin-right: $space*1.5;
    }

    .dv-title-text-loading {
        height: 32px;
        width: 500px;
    }

    /* Nav */
    .dv-nav-item {
        margin-right: $space*3;
        margin-bottom: $space*4;
        height: 20px;
    }

    /* Title inside page */
    .dv-inner-title {
        margin-bottom: 0;
        height: 40px;
    }

    .dc-layout__main-content-wrapper {
        width: 100%;
        // height: 500px;
    }

    /* Inner blocks */
    .section-item {
        height: 180px;
        margin-bottom: $space*2;
    }
}


/* ------------------------------------ *\
    NOT FOUND
\* ------------------------------------ */
.dc-layout-not-found {
    position: fixed;
    box-sizing: border-box;
    height: 100%;
    width: 100%;

    &__content {
        // margin-top: $space*6;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
    }

    &__text {
        font-size: $font-size-m;
        font-family: $font-family-main;
    }

    &__subtext {
        font-size: $font-size-m;
    }
}


/* ------------------------------------ *\
   #MEDIA-QUERIES
\* ------------------------------------ */
@media all and (max-width: $page-max-width + $space*4) {

    .dc-layout__title {
        .n7-inner-title {
            padding-right: $space*2;
            padding-left: $space*2;
        }
    }
}

/* Screen media - managing screen-only styles eg: hide some print-only elements */
@media screen {
    
    /* Hide print-only elements */
    .dc-disclaimer,
    .dc-footer-print,
    .dc-page-footer-space,
    .dc-print-top-logo {
        display: none;
    }
}