/**
 * GLOBAL
 *
 * Global styles, tags and common classes
 */

// TODO: Decide if we want to style the "body" level and if we want to differentiate between the embed and standalone usage.

/* ------------------------------------ *\
   #TAGS
\* ------------------------------------ */
html, body {
    // height: 100%;
    // margin: 0;
    // padding: 0;
    // font-size: 16px;
}

body {
    // box-sizing: border-box;
    background-color: $color-gray-02;
    // color: $color-text;
    // font-family: $font-family-main;
    // -webkit-font-smoothing: antialiased;
}

:focus {
    // outline: 0;
}

.info-not-available {
    color: $color-text-light;
    font-style: italic;
}


/* ------------------------------------ *\
    EMPTY-STATE
\* ------------------------------------ */
.empty-alert {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $font-size-m;
    font-family: $font-family-main;
    font-style: italic;
    color: $color-text-light;

    &.empty-alert-fixed-height {
        min-height: 200px;
    }
}


/* ------------------------------------ *\
   #MEDIA-QUERIES
\* ------------------------------------ */
@media all and (max-width: $breakpoint-laptop) {
}

@media all and (max-width: $breakpoint-ipad-portrait) {
}

@media all and (max-width: $breakpoint-smartphone-landscape) {
}

/* Retina */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}
