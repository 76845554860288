/**
 * METADATA-VIEWER
 *
 * Component to display metadata organized and nested in groups.
 */
 
/* ------------------------------------ *\
   #METADATA-VIEWER
\* ------------------------------------ */
.n7-metadata-viewer {
    font-size: $font-size-m;

    &__group-wrapper {
        .n7-metadata-viewer__group-wrapper {
        }
    }
    
    &__group {
        margin-bottom: 0;
    }

    &__group-title {
    }

    &__group-content {
        display: block;
        @include consistent;
    }

    &__item {
        float: left;
        padding-bottom: $grid-gutter-v;
        box-sizing: border-box;
        width: calc( ( (100% - 4px) - ( #{$grid-gutter-h} * 3) ) / 4 );
        margin-right: $grid-gutter-h;
        
        &:nth-of-type(4n+4) {
            margin-right: 0;
        }
    }

    &__item-label {
        display: block;
        margin-bottom: $space/2;
    }

    &__item-value {
        display: block;
    }
}


/* ------------------------------------ *\
   #MEDIA-QUERIES
\* ------------------------------------ */
@media all and (max-width: $breakpoint-laptop) {
}

@media all and (max-width: $breakpoint-ipad-portrait) {
}

@media all and (max-width: $breakpoint-smartphone-landscape) {
}

/* Retina */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}