/**
 * TABLE
 *
 * Table component used to display values in a table and to edit list of values in a table.
 * Cell can contain values, HTML snippets and input fields.
 */

/* ------------------------------------ *\
    TABLE
\* ------------------------------------ */
.n7-table {
    margin-bottom: $space;

    /* Contents */
    &__table-body-row:nth-child(even) {
        background-color: $table-bg-even;
    }

    &__table-body-row:nth-child(odd) {
        background-color: $table-bg-odd;
    }

    &__table-body-cell {
        vertical-align: top;

        &:first-of-type {
            font-weight: $font-weight-bold;
        }

        ul {
            margin: 0;
            padding-left: 0;
            list-style: none;

            li {
                margin-bottom: $space/2;
                padding-bottom: $space/2;
                border-bottom: $table-border-size solid $color-border-normal;

                &:last-of-type {
                    margin-bottom: 0;
                    padding-bottom: 0;
                    border-bottom: none;
                }
            }
        }
    }
}


/* ------------------------------------ *\
   #MEDIA-QUERIES
\* ------------------------------------ */
@media all and (max-width: $breakpoint-laptop) {
}

@media all and (max-width: $breakpoint-ipad-portrait) {
}

@media all and (max-width: $breakpoint-smartphone-landscape) {
}

/* Retina */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}