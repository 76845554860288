/**
 * SECTION
 *
 * Project section
 */

/* ------------------------------------ *\
   #SECTION-LAYOUT
\* ------------------------------------ */
.dc-section {
    // padding: $space*2 $space*2 0 $space*2;
    padding: 0;
    margin-bottom: $space*4;
}


/* ------------------------------------ *\
   #SECTION-TITLE
\* ------------------------------------ */
.dc-section-title {
    display: flex;
    align-items: baseline;
    margin-bottom: $space*3;
    border-bottom: 2px solid $color-border-normal;

    &__title {
        font-size: $font-size-xl;
    }

    &__right-content {
        margin-left: auto;
        padding-left: $space/3;
    }

    &__right-content-label {
        margin-right: $space/2;
        font-size: $font-size-xs;
        color: $color-text-light; // $color-text-lighter;
    }

    &__right-content-value {
        font-weight: $font-weight-black;
        font-size: $font-size-xl;
    }
}


/* ------------------------------------ *\
   #SECTION-CONTENT
\* ------------------------------------ */
.dc-section-content-description {
    font-size: $font-size-m;
    line-height: 1.7;
}

.dc-section {
    /* Graph */
    .dc-graph-caption  {
        margin-top: $space;
        font-size: $font-size-s;
    }

    .n7-inner-title {
        padding-top: 0;
        padding-right: 0;
        padding-left: 0;

        &__title {
            margin-bottom: 0;
            font-size: $font-size-m;
        }

        &__subtitle {
            font-size: $font-size-xs;
            color: $color-text-light; // $color-text-lighter;
        }
    }
}


/* ------------------------------------ *\
   #MEDIA-QUERIES
\* ------------------------------------ */
@media all and (max-width: $footer-breakpoint-desktop) {
}

@media all and (max-width: $breakpoint-ipad-portrait) {
}

@media all and (max-width: $footer-breakpoint-mobile) {
}

/* Retina */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}
