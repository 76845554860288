//
// COLOR SCHEME
//
// Here we define all colors used in components and project
//

// ------------------------------------ //
// #COLOR-SCHEME
// ------------------------------------ //
// Grayscale (background, borders, text)
$color-gray-00: #ffffff;
$color-gray-01: #fafbfc;
$color-gray-02: #f7f7f7;
$color-gray-03: #d6d6d6;
$color-gray-04: #8a8a8a;
$color-gray-05: #474747;
$color-gray-06: #292d2e;


// Brand colors
$color-main: #185d7d; // #2ebbc7;
$color-second: #185d7d; // 185d7d; 
$color-third: #0fa5eb;
$color-fourth: #2e5bff;
$color-text-hover: #12455d; //$color-main; /* Used for text link in hover state */

// Status colors
$color-error: #d12c47;
$color-warning: #fbb800;
$color-success: #2fa358;
$color-error-light: #fae9ec;
$color-warning-light: #fdedd3;
$color-success-light: #e2ebd9;

// Categories color variables
$color-people: #3a81f2; // Fallback
$color-people: var(--color-persona);

$color-places: #f2cd3a; // Fallback
$color-places: var(--color-luogo);

$color-concepts: #5eab7b; // Fallback
$color-concepts: var(--color-concetto);

$color-organizations: #c48731; // Fallback
$color-organizations: var(--color-organizzazione);

$color-things: #c43631; // Fallback
$color-things: var(--color-cosa-notevole);