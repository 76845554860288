/**
 * FOOTER
 *
 * General footer structure, (hopefully) suitable for all needs.
 */

/* ------------------------------------ *\
   #FOOTER
\* ------------------------------------ */
.n7-footer {
    // background-color: $footer-background-color;
    box-shadow: 0 5px 40px rgba(1,1,1,.06);

    &__content {
        // display: flex;
        // max-width: $page-max-width;
        // margin-right: auto;
        // margin-left: auto;
        // padding: $space*3 0;

        a {
            text-decoration: underline;
        }
    }

    &__column {
        // flex: 1;
        // padding: 0 $grid-gutter-h/2;

        &:first-of-type {
            // padding-left: 0;
        }

        &:last-of-type {
            // padding-right: 0;
        }

        &-nav {
            &-list {
                // padding-left: 0;
                // list-style: none;
                // font-size: $font-size-xs;
            }

            &-item {
                // margin-bottom: $space/2;
            }

            &-link {
                // text-decoration: underline;
                // cursor: pointer;
            }
        }

        &-title,
        &-text {
            // font-size: $font-size-xs;
        }

        &-images {
            // padding-top: $space;
            
            img {
                // width: auto;
                // height: $footer-image-height;
                // margin-right: $space/2;

                &:last-of-type {
                    // margin-right: 0;
                }
            }
        }
    }
}

/* ------------------------------------ *\
   #MEDIA-QUERIES
\* ------------------------------------ */
@media all and (max-width: $footer-breakpoint-desktop) {
    .n7-footer {
        &__column {
            
            &:first-of-type {
                // padding-left: $grid-gutter-h/2;
            }

            &:last-of-type {
                // padding-right: $grid-gutter-h/2;
            }
        }
    }
}

@media all and (max-width: $breakpoint-ipad-portrait) {
}

@media all and (max-width: $footer-breakpoint-mobile) {
    .n7-footer {
        &__content {
            // flex-flow: column nowrap;
        }

        &__column {
            // padding-bottom: $grid-gutter-h/2;
        
            &:last-of-type {
                // padding-bottom: 0;
            }

            &-nav {
                &-list,
                &-item {
                    // margin-bottom: 0;
                }

                &-list {
                    // margin-top: 0;
                }
            }

        }
    }
}

/* Retina */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}
