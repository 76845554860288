/**
 * CHART
 *
 * All styles related to charts: tooltip, empty states...
 */

/* ------------------------------------ *\
   #CHART-NO-DATA-ALERT
\* ------------------------------------ */
.dv-chart-nodata {
    display: block;
    box-sizing: border-box;
    width: 100%;
    min-height: 60px;
    margin-bottom: $space*2;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: $color-background-light;
    // border: 1px solid $color-border-normal;
    border-radius: 3px;
    font-size: $font-size-xs;
    color: $color-text-light; // $color-text-lighter;
    font-style: italic;
    box-shadow: inset 0 0 20px rgba(0,0,0,.07), inset 0 0 3px rgba(0,0,0,.05);

    display: table;
    text-align: center;

  div {
    display: table-cell;
    padding: $space*1.5 $space*2;
    vertical-align: middle;
  }
}


/* ------------------------------------ *\
   #CHART-LOADER
\* ------------------------------------ */
.dv-chart-loading {
    .dv-chart-placeholder {
        height: 80px;
    }
}


/* ------------------------------------ *\
   #CHART-TOOLTIP
\* ------------------------------------ */
.dc-chart-tooltip {
    display: inline-block;
    max-width: $chart-tooltip-max-width;
    width: fit-content;
    padding: $space;
    border-radius: $border-radius;
    border: 1px solid $chart-tooltip-border-color;
    box-shadow: $chart-tooltip-box-shadow;
    font-size: $font-size-s;
    background-color: $item-background-color;

    &__name {
        color: $color-text-light; // $color-text-lighter;
    }

    &__data {
        color: $color-text-light; // $color-text-lighter;
        &-value {
            margin-right: $space/2;
            font-weight: $font-weight-bold;
        }
    }
}

