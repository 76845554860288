.dc-section-content {
    /* Inner title */
    .dc-section-graph-wrapper {
        .n7-inner-title {
            padding: 0;
        }
    
        .n7-inner-title__title  {
            font-size: $font-size-m;
            margin-bottom: $space/2;
        }
    
        .n7-inner-title__subtitle  {
            font-size: $font-size-xs;
        }
    }
}