/**
 * ITEM
 *
 * ITEM component style
 */

/* ------------------------------------ *\
   #ITEM
\* ------------------------------------ */
.dc-item {
    display: flex;
    align-items: center;
    padding: $space;
    border-radius: $item-border-radius;
    background: $item-background-color;
    box-shadow: $item-box-shadow;

    &__label {
        display: flex;
        align-items: center;
        justify-content: center;
        height: $item-label-min-height - 1;
        min-height: $item-label-min-height;
        flex: 0 0 33%;
        max-width: 33%;
        margin-right: $space*2;
        padding: $space;
        border-radius: $item-border-radius;
        background-color: $item-label-background;  
        text-align: center;
        color: $item-label-color;   
        text-transform: uppercase;
        font-weight: $font-weight-bold;
        font-family: $font-family-headings;
        line-height: 1.2;
    }

    &__content {
        font-size: $font-size-s;
        &::first-letter {
            text-transform: uppercase;
        }
    }

    &-wrapper {
        // display: grid;
        // column-gap: $grid-gutter-h;
        // row-gap: $grid-gutter-v;
        padding: $space*2;
        background: $item-wrapper-background;
        border-radius: $item-border-radius;

        .dc-item {
            margin-bottom: $grid-gutter-h;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}
