/**
 * MODAL
 *
 * Css style for the modal.
 */

/* ------------------------------------ *\
    MODAL
\* ------------------------------------ */
.dc-modal {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.6);
    display: block;
    z-index: 1;

    &.dc-modal-selection {
        background-color: $color-background-medium;
    }

    &__content {
        background-color: $color-background-base;
        margin: 0 auto;
        padding: 0;
        border: 0;
        max-width: 800px;
        border-radius: $border-radius;
        box-shadow: 0 8px 30px rgba(0,0,0,.2);

        // Vertical align
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }

    &__header {
        background-color: $color-background-light;
        border-radius: $border-radius $border-radius 0 0;
    }

    .n7-inner-title__title {
        margin-bottom: 0;
    }

    &__close {
        position: absolute;
        right: $space*2;
        top: $space*2;
        font-size: $font-size-xl;
        color: $color-text-light;
        cursor: pointer;
        transition: all $transition-durations;

        &:hover {
            color: $color-text-link;
        }
    }

    &__title {
        margin: 0;
        font-size: $font-size-xxl;
        font-family: $font-family-main;
    }

    &__body {
        padding: $space*3;
    }

    &__text {
        font-size: $font-size-m;
    }

    &__footer {
        padding: 0 $space*3 $space*3 $space*3;
        text-align: center;
    }
}

/* ------------------------------------ *\
    INPUTS
\* ------------------------------------ */
.dc-modal {
    .n7-input-radio {
        margin-top: $space*2;
    }

    .n7-input-radio__wrapper {
        position: relative;
    }

    .n7-input-radio__label {
        display: block;
        margin-bottom: $space;
        padding: $space*3 $space*3 $space*3 $space*5;
        background-color: $color-background-base;
        box-shadow: 0 1px 1px rgba(0,0,0,.1), 0 5px 20px rgba(0,0,0,.05);
        border-radius: $border-radius;
        cursor: pointer;
        vertical-align: middle;
        font-size: $font-size-m;
        transition: all $transition-durations;

        &:hover {
            background-color: $color-background-light;
            box-shadow: 0 1px 1px rgba(0,0,0,.2), 0 5px 20px rgba(0,0,0,.08);
        }

        &::first-letter {
            text-transform: uppercase;
        }
    }

    .n7-input-radio__input {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: $space*2;
        // -webkit-appearance: none;
        // -moz-appearance: none;
    }

    /*
    .n7-input-radio__input:checked,
    .n7-input-radio__input:not(:checked) {
        position: absolute;
        left: -9999px;
    }
    
    .n7-input-radio__input:checked + label,
    .n7-input-radio__input:not(:checked) + label
    {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: #666;
    }
    
    .n7-input-radio__input:checked + label:before,
    .n7-input-radio__input:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 18px;
        height: 18px;
        border: 1px solid #ddd;
        border-radius: 100%;
        background: #fff;
    }

    .n7-input-radio__input:checked + label:after,
    .n7-input-radio__input:not(:checked) + label:after {
        content: '';
        width: 12px;
        height: 12px;
        background: #F87DA9;
        position: absolute;
        top: 4px;
        left: 4px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }

    .n7-input-radio__input:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    .n7-input-radio__input:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    */

    .n7-input-radio__input:checked + .n7-input-radio__label {
        // font-weight: $font-weight-bold;
        // background-color: $color-background-light;
        // box-shadow: 0 1px 1px rgba(0,0,0,.2), 0 5px 20px rgba(0,0,0,.08);
    }
}