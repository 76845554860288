/**
 * CARD
 *
 * Card component style
 */

/* ------------------------------------ *\
   #CARD
\* ------------------------------------ */
/* Wrapper grid */
.dc-card-wrapper {
    display: block;
    // flex-flow: row wrap;
    // column-gap: $grid-gutter-v;
    // row-gap: $grid-gutter-h;
    padding: $space*2;
    background: $card-wrapper-background;
    border-radius: $card-border-radius;
    box-shadow: $card-wrapper-box-shadow;
    
    .dc-card {
        margin-bottom: $grid-gutter-h;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.dc-card {
    display: block;
    position: relative;
    min-height: 50px;
    box-sizing: border-box;
    padding: $space*2 $space*2 0 $space*2;
    background: #fff;
    border-radius: $card-border-radius;
    box-shadow: $card-box-shadow;
    overflow: hidden;

    &__icon {
        position: absolute;
        top: $space*1.2;
        left: $space*1.2;
        align-self: baseline;
        padding: $space;
        border-radius: 50%;

        &.orange {
            background: $color-warning-light;
            color: $color-warning;
        }

        &.purple {
            background: $color-error-light;
            color: $color-error;
        }

        &.green {
            background: $color-success-light;
            color: $color-success;
        }
    }
    
    &__text {
        margin-left: $space*5;
        margin-bottom: $space*2;
        font-size: $font-size-s;
        &::first-letter {
            text-transform: uppercase;
        }
    }
}

/* ------------------------------------ *\
   #MEDIA-QUERIES
\* ------------------------------------ */
@media all and (min-width: $breakpoint-ipad-portrait) {
    .dc-card {
        /* Wrapper grid */
        &-wrapper {
            &-col2 {
                .dc-card {
                    &:nth-child(2n + 1):nth-last-child(-n + 2),
                    &:nth-child(2n + 1):nth-last-child(-n + 2) ~ .dc-card {
                        margin-bottom: 0;
                    }
                }
            }

            &-col3 {
                .dc-card {
                    &:nth-child(3n + 1):nth-last-child(-n + 3),
                    &:nth-child(3n + 1):nth-last-child(-n + 3) ~ .dc-card {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}