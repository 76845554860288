/**
 * PRINT
 *
 * Css resets and settings for print layout
 */

 $grid-gutter-h: 5mm;

/* ------------------------------------ *\
    GENERAL PRINT SETTINGS
\* ------------------------------------ */
@page {
    size: A4;
    margin: 0;
}

// @page :first {
//     margin: 0;
// }

html,
body {
    // height: 100%;
    margin: 0;
    padding: 0;
    background-color: transparent;
    font-size: 12pt;
    overflow: visible !important;
    page-break-after: avoid !important;
    page-break-before: avoid !important;
}

body {
    // width: 200mm;
    // width: 210mm;
}


/* ------------------------------------ *\
    STRUCTURE
\* ------------------------------------ */
.pagebreak {
    clear: both;
    page-break-after: always;
}

.dc-unbreak {
    page-break-inside: avoid;
}

.dc-break-before {
    page-break-before: always;
}

.dc-break-after {
  page-break-after: always;
}

.dc-disclaimer {
    display: block;
    padding-top: 8pt;
    padding-bottom: 8pt;
    font-size: 6pt;
}

/* Title of each page */
.dc-layout__inner-title {
    padding: 5mm 15mm 8mm 0;
    background: transparent;
    box-shadow: none;
    overflow: visible;
}

.dc-layout__inner-title strong {
    display: inline;
}

/* Wrapper of all elements inside each page */
.dc-layout__section-wrapper {
    // max-height: 30cm;
    padding: 0 0 8mm 0;
    // border: 2px solid gold;
}

.dc-tab-section {
    padding: 4mm 8mm 4mm 8mm;
    page-break-inside: avoid;
}

/* Space for header logo */
.dc-top-padding {
    .dc-section-title__title {
        padding-top: 11mm;
    }
}

/* Table layout for print header and footer */
.dc-layout-table {
    page-break-after: always;

    > thead {
        display: table-header-group;
        width: auto;
    } 

    > tfoot {
        display: table-footer-group;
    }
}

/* Long text section */
.dc-section-content-description {
    padding-left: 20mm;
    padding-right: 20mm;
    font-size: 12pt;

    p {
        line-height: 1.8;
    }

    & + .dc-section-content-description {
        padding-top: 15mm;
    }
}

// .dc-section-content-descriptio-start 


/* Hidden elements for print */
n7-header, .dc-layout__title, n7-footer {
    display: none;
}


/* ------------------------------------ *\
    COVER PAGES
\* ------------------------------------ */
/* Pages that contain a full screen image */
.dc-cover-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    z-index: 100;
    line-height: 0; /* fix the overflowing page image height issue */
    -webkit-print-color-adjust: exact;
}

.dc-cover {
    width: 100%;
    height: 100%; 
    overflow: hidden;
    line-height: 0; /* fix the overflowing page image height issue */
    -webkit-print-color-adjust: exact;

    img {
        max-width: 100%;
        page-break-after: avoid;
        height: auto;
        line-height: 1;
    }
}


/* ------------------------------------ *\
    DATA WIDGET WRAPPER
\* ------------------------------------ */
/* Components that wraps a title on top and one or two indicators or graphs */
/* Inner title of data widget wrapper */
.dc-section-content {
    .dc-section-graph-wrapper {
        .n7-inner-title__title {
            font-size: 10pt; // $font-size-xs;
        }

        .n7-inner-title__subtitle {
            font-size: 8pt; // $font-size-xs;
        }
    }
}
  
/* Make height of component with 2 rows automatic */
.dc-section-graph-wrapper-2-rows {
    .dv-data-widget-wrapper__content {
        > * {
            height: auto;
        }
    }
}


/* ------------------------------------ *\
    GRAPHS
\* ------------------------------------ */
.dc-graph-title {
    &__title {
        margin-bottom: 2pt;
        font-size: 8pt;
        line-height: 1.2;
    }

    &__subtitle {
        font-size: 7pt;
        line-height: 1.2;
    }
}

.dc-graph-caption {
    margin-top: 10pt;
    font-size: 8pt;
}

.dv-chart-print {
    display: block;
    // width: 100%;
    width: auto;
    line-height: 0;
    // height: 5cm;
    
    img {
        max-width: 100%;
        height: auto;
    }
}

.n7-chart {
    width: 100%;
}

/* No data alert */
.dv-chart-nodata {
    font-size: 8pt;
    font-weight: $font-weight-semibold;
}

/* Special charts height for KPI Benchmark page
to make all charts fit in the A4 page */
.dc-tab-section-kpi-benchmark {
    .dv-chart-print {
        text-align: center;
        img {
            width: auto;
            height: 5cm;
        }
    }
}

/* Special charts height for Sector analysis page
to make all charts fit in the A4 page */
.dc-tab-section-sector-analysis {
    .dv-chart-print {
        text-align: center;
        img {
            width: auto;
            height: 5cm;
        }
    }
}

/* Special charts height for Main indicators page
to make all charts fit in the A4 page */
.dc-tab-section-main-indicators {
    .dv-chart-print {
        width: 7cm;
    }
}

/* ------------------------------------ *\
    METADATA-VIEWER
\* ------------------------------------ */
.dc-layout {
    .n7-metadata-viewer {
        &__group-content {
            display: block;
            margin-right: 0;
        }

        .n7-metadata-viewer__item {
            display: inline-block;
            vertical-align: top;
            max-width: none;
            width: 24%;
            margin-bottom: 0;
            margin-right: 0;
            padding-right: 10pt;
            padding-bottom: 10pt;
            border-right: none;

            &:nth-child(4n + 1):nth-last-child(-n + 4),
            &:nth-child(4n + 1):nth-last-child(-n + 4) ~ .n7-metadata-viewer__item {
                // margin-bottom: 0;
                // padding-bottom: 0;
            }
        }
    
        .n7-metadata-viewer__item-label {
            font-size: 9pt;
        }

        .n7-metadata-viewer__item-value {
            font-size: 8pt;
        }
    }
}


/* ------------------------------------ *\
    HEADER-LOGO
\* ------------------------------------ */
.dc-page-header-space,
.dc-print-top-logo {
    height: 15mm;
}

.dc-print-top-logo {
    width: 100%;
    padding: 4mm 2mm 0 0;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1;
    box-sizing: border-box;
    text-align: right;

    img {
        width: auto;
        max-height: 100%;
    }
}

/* ------------------------------------ *\
    FOOTER
\* ------------------------------------ */
.dc-page-footer-space,
.dc-footer-print {
    height: 13mm;
}

.dc-footer-print {
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    z-index: 1;
    padding: 0 8mm 15mm 8mm;

    &__message {
        width: 150mm;
        float: left;
        font-size: 8pt;
    }

    &__logo {
        width: 50mm;
        float: right;
    }

    img {
        max-width: 100%;
        max-height: 100%;
    }
}

/* ------------------------------------ *\
    CARDS/ITEMS
\* ------------------------------------ */
/* Cards and items used in "Variabili critiche", "Opportunità e minacce", "Linee Guida commerciali" */
.dc-card,
.dc-item {
    padding: 10pt;
    box-shadow: none;
    border: 1px solid $color-border-normal;
    page-break-inside: avoid;

    &-wrapper {
        display: block;
        padding: 0;
        background: none;
        box-shadow: none;
        // page-break-inside: avoid;

    &-col2 {
        // column-count: 2;


        .dc-card,
        .dc-item {
                max-width: initial;
                // width: 99%;
                margin-right: 0;
                float: left;
                width: calc( ( (100% - 1mm) - ( #{$grid-gutter-h} * 1) ) / 2 );
                margin-right: $grid-gutter-h !important;
            
                &:nth-of-type(2n+2) {
                    margin-right: 0 !important;
                }
            }
        }
    }

    &__icon {
        display: inline;
        margin-right: 3pt;
        padding: 0;
        background: transparent !important;
    }

    &__text {
        font-size: 8pt;
    }

    .dc-card-wrapper &,
    .dc-item-wrapper & {
        margin-bottom: 10pt;
    }
}

.dc-item {
    &__label {
        background: none;
        color: $color-text;
    }
}


/* ------------------------------------ *\
    GRIDS
\* ------------------------------------ */
/* Moved into Components */
/* [class*="n7-grid-"],
[class^="n7-grid-"] {
    display: block;
    float: none;
    clear: both;
    @include consistent;

    > * {
        box-sizing: border-box;
    }
}

.n7-grid-3 > * {
    float: left;
    width: calc( ( (100% - 1mm) - ( #{$grid-gutter-h} * 2) ) / 3 );
    margin-right: $grid-gutter-h !important;
    
    &:nth-of-type(3n+3) {
        margin-right: 0 !important;
    }
}

.n7-grid-2 > * {
    float: left;
    width: calc( ( (100% - 1mm) - ( #{$grid-gutter-h} * 1) ) / 2 );
    margin-right: $grid-gutter-h !important;

    &:nth-of-type(2n+2) {
        margin-right: 0 !important;
    }
} */


/* ------------------------------------ *\
    TABLES
\* ------------------------------------ */
.n7-table {
    margin-bottom: 10pt;

    &__table {
        display: table;
        text-align: left;
    }

    &__table-body {
        display: table-row-group;
        text-align: left;
    }

    &__table-body-row {
        display: table-row;
        text-align: left;
    }

    &__table-body-cell {
        display: table-cell;
        padding: 2pt;
        text-align: left;
        font-size: 7pt;

        &:before {
            display: none;
        }
    }
}


/* ------------------------------------ *\
    VARIOUS
\* ------------------------------------ */
.dc-section {
    margin-bottom: 10pt;
    padding: 0;
    // page-break-after: always;
    // page-break-inside: avoid;

    .n7-inner-title {
        &__title {
            margin-bottom: 2pt;
            font-size: 8pt;
            line-height: 1.2;
        }

        &__subtitle {
            height: 12pt;
            font-size: 7pt;
            line-height: 1.2;
        }

        &__tools {
            display: none;
        }
    }
}

.dc-layout {
    &:last-child {
        page-break-after: auto;
    }

    &__content {
        margin-bottom: 0;
    }

    &__main-content-wrapper {
        background: transparent;
        box-shadow: none;
        overflow: visible;
    }

    .n7-inner-title {
        padding-top: 0;
        padding-bottom: 0;
    }

    &-row {
        margin-bottom: 0;

        &.dc-row-has-margin {
        margin-bottom: 16pt;
        }
    }

    &-row-unbreak {
        page-break-inside: avoid;
    }

    &__content {
        display: block;
    }
}

.n7-data-widget {
    &__main-title {
        font-size: 14pt;
    }

    &__sub-title {
        font-size: 8pt;
        }
    }


    .dc-layout__inner-title-image {
        display: block;
        height: auto;
        margin-bottom: 10pt;
        // padding: 0;
        background-image: none !important;
        color: $color-text;
        font-size: 20pt;
    }

    /* Section title style */
    .dc-section-title {
    display: block;
    margin-bottom: 10pt;

    &.has-right-content {
            > * {
            display: inline-block;
            width: 50%;
        }
    }

    &__title {
        font-size: 12pt;
        white-space: nowrap;
    }

    &__right-content {
        padding-left: 0;
        text-align: right;
    }

    &__right-content-label {
        font-size: 6pt;
    }
    
    &__right-content-value {
        font-size: 8pt;
    }
}

