.dc-graph-title {
    margin-bottom: $space*2;
    
    &__title {
        margin-bottom: $space/2;
        font-family: $font-family-main;
    }

    &__subtitle {
        color: $color-text-light; // $color-text-lighter;
        font-size: $font-size-xs;
    }
}